
export const GET_MOVIES = 'MOVIES/GET';
export const GET_MOVIESDETAIL = 'MOVIES/GETDETAIL';
export const ADD_MOVIES = 'MOVIES/ADD';
export const SET_PAGES_INDEX = 'PAGES/SETINDEX';
export const SET_PAGES_SIZE = 'PAGES/SETSIZE';

export const SET_SEARCH = 'SEARCH/SET';

export const SET_MODE = 'MODE/SET';


export const SET_LOADING = 'LOADING/SET';

